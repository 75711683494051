.service-card-item{

    position: relative;
}

.service-card-item img{
    width: 300px;
    border-radius: 30px;
    height: 200px;
    filter: blur(2px);
}
.service-card-text {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    color: #F2EFE8; 
    font-size: 24px; 
    font-weight: 500; 
    text-align: center;
    z-index: 1; 
    letter-spacing: 2px;
    width: 90%;
  }

  .service-card-overlay {
    position: absolute; /* Establece el posicionamiento absoluto */
    top: 0;
    left: 0;
    border-radius: 40px;

    width: 100%; /* Ajusta el ancho al 100% del contenedor */
    height: 100%; /* Ajusta la altura al 100% del contenedor */
    background-color: rgba(32,50,72, 0.7); /* Color de fondo semi-transparente */
  }
