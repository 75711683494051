
.intro-we-are{
    padding: 50px 0;
    gap:50px;
    align-items: center;
    margin: auto;
}

@media (max-width: 1024px) { .intro-we-are{
    padding: 50px 25px;
    gap:50px;
    align-items: center;
    margin: auto;

}
}
.avatar-wrapper{
    max-width: 345px;
}

.avatar-wrapper img {
    width: 100%;
}

.we-are{
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    gap: 200px;
}

.avatar-wrapper h5 {
    letter-spacing: 1.5px;
}

@media (max-width: 992px) {

    .we-are{
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 70px;
    }

 }