.hero-img {
    width: 100%;
    height: 70vh;
background-image: url("../../assets/imagenes/banner.jpg");
background-repeat: no-repeat;
background-size: cover;
position: relative;
}


  .hero-overlay img{
    position: absolute; 
    width: 50%;
    top: 40%; 
    left: 50%; 
    transform: translate(-50%, -50%);
  }

  @media (max-width: 425px) { 

    .hero-overlay img{
        width: 85%;
      }
 }
  