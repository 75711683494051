.logo {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

.logo img{
    width: 150px;
    height: 35px;
  }

  
  .logo div{
    margin-left: 10px;
    font-weight: 600;
  }