.intro{
    padding: 100px 0;
    gap:50px;
    align-items: center;
    margin: auto;
    max-width: 60%;
}

@media (max-width: 767.98px) { .intro{
    padding: 50px 25px;
    gap:50px;
    align-items: center;
    margin: auto;
    max-width: 100%;
}
}

.intro img {

    width: 150px;
}
.intro h1{
    letter-spacing: 1.5px;
    line-height: 50px;
    font-weight: 500;
   margin-bottom: 20px;
}

.intro p{
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
}