body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color:rgba(242, 239, 232, 0.2);

}

.bg-dark{
  background-color: #203248 !important;
  color:#F2EFE8 !important
}

.bg-dark-light{
  background-color: rgba(32,50,72, 0.2) !important;
  color:#203248 !important;
}

.bg-light{
  background-color:#F2EFE8 !important;
  color:#203248 !important
}

a:hover {
  color: #F2EFE8
}

.body-content{
  min-height: calc(100vh - 70px);
}


.saber-mas{
  display: flex;
  justify-content: center;

}
.saber-mas a {
  display: block;
  color: #203248;
  text-align: center;
  padding: 14px 8px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}
.saber-mas a:hover {
  text-decoration: underline #203248;
}

.divider {
  height: 8px; /* Altura del divisor */
  background: linear-gradient(to right, transparent, rgba(32, 50, 72));
}

p{
  font-size: 16px;
}

h1{
  font-size: 48px;
}

h2{
  font-size: 40px;
  font-weight: 500;
}

h3{
  font-size: 36px;
}

h6{
 text-decoration: underline;
 cursor: default;
}

@media (max-width: 767.98px) { 
  h1{
  font-size: 36px;
  }

  h2{
    font-size: 32px;
  }

  h3{
    font-size: 28px;
  }
}


