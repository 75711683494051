.cr-footer{

nav{
    justify-content: space-between;
    padding: 20px 80px;
    align-items: center;
}
}

.footer-items ul{
   padding: 0;
    list-style: none;
}

.footer-items{
    gap:50px;
}

.cr-icon,  .cr-servicios{
    font-size: 14px;
}

.cr-servicios{
    max-width: 300px;
}

.cr-servicios a{
    text-decoration: none;
    color: #F2EFE8
}

.cr-servicios a:hover{
    text-decoration: underline;
    color: #F2EFE8
}

.cr-icon img{
    height: 30px;
    margin-right: 20px;
}

.ubicacion{
    max-width: 200px;
}

.cr-icon a{
    text-decoration: none;
    color: #F2EFE8
}

.cr-icon a:hover{
    text-decoration: underline;
    color: #F2EFE8
}

.logo-footer img{
    width: 250px;
}

.bottom-footer a{
    text-decoration: none;
    color: #F2EFE8
}

.bottom-footer a:hover{
    text-decoration: underline;
    color: #F2EFE8
}

@media only screen and (max-width:1025px) and (min-width: 770px) {
    .cr-footer{

        nav{
            padding: 20px 10px;
        }
        }
 }
@media (max-width: 769px) { 
    .logo-footer{
        margin-bottom: 60px;
    }

    .logo-footer img{
        width: 200px;
    }


    .footer-items{
        gap:30px;
    }
    .cr-footer{
        nav{
            justify-content: space-between;
            padding: 20px 10px;
            align-items: center;
        }
    }
  }