.services-wrapper{
    display: flex;
    width: 100%;
    gap: 50px;
    align-items: center;

}

.services-wrapper .container{
    padding: 75px;
   
}
@media (max-width: 767.98px) { .services-wrapper .container{
    padding: 25px;
   
} }

.service-content{
    letter-spacing: 1.5px;
}



.service-items ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap:40px;
    padding: 20px 0;
    justify-content: center;
}