.cr-navbar{
  position: sticky; 
  top: 0;
  width: 100%;
  z-index: 1000;
  
nav {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  li {
    float: left;
    font-weight: 500;
  }
  li a {
    display: block;
    color: #F2EFE8;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  li a:hover {
    text-decoration: underline #F2EFE8;
  }

  .ham-menu{
    cursor: pointer;
  }

  
  .burger-menu {
    visibility: hidden; /* Al alterar las márgenes y el height no es necesaria esta propiedad */
    opacity: 0;
    height: 0;
    padding: 0;
  }
  
  .open {
    visibility: visible; /* Al alterar las márgenes y el height no es necesaria esta propiedad */
    opacity: 1;
    height: auto;
    padding: 15px 0px;
    transition: opacity .5s linear;
  }
}